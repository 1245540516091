const DEFAULT_STATE = {
  inviteByCode: {}
}

const replaceState = (state, params, payload) => {
  var newState = { ...state };

  var oldParams = newState.inviteByCode[payload.code] || {};
  newState.inviteByCode[payload.code] = Object.assign(oldParams, params);

  return newState;
}

function invitationReducer (state = DEFAULT_STATE, action) {
  if (action.type === 'INVITATION.FETCH') {
    return replaceState(state, {
      loading: true,
      error: false,
      found: false,
      available: false,
      data: null
    }, action.payload);
  }

  if (action.type === 'INVITATION.FETCH_OK') {
    return replaceState(state, {
      loading: false,
      error: false,
      found: true,
      available: true,
      data: { ...action.payload.data }
    }, action.payload);
  }

  if (action.type === 'INVITATION.FETCH_ERROR') {
    return replaceState(state, {
      loading: false,
      error: true,
      found: false,
      available: false,
      data: null
    }, action.payload);
  }

  return state;
}

export default invitationReducer;