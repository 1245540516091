import React from 'react';
import { withRouter } from 'react-router-dom';

import { Box, Title } from '../../components/Whitebox';
//import Button from '../../components/Button';
//import ButtonContent from '../../components/ButtonContent';
import User from '../../components/User';
import ConfirmButton from '../../components/ConfirmButton';

const validateRedirectUri = (url) => {
  return true;
}

const InvitationPage = (props) => {
  var params = new URLSearchParams(props.location.search);

  if (!params.has('redirectUri')) {
    return (<p>Paramêtro "redirectUri" é inválido</p>)
  }

  var redirectUri = params.get('redirectUri');

  if (redirectUri) {
    if (!validateRedirectUri(redirectUri)) {
      return (<p>Paramêtro "redirectUri" é inválido</p>)
    }
  }

  const createRedirectLink = (token) => {
    return `${redirectUri}?token=${token}`;
  }

  const handleConfirm = (e) => {
    window.alert(`URL: ${createRedirectLink('AAAA')}`);
  }

  const handleCancel = (e) => {
    console.log(e);
  }

  const handleConfirmClick = (confirm, e) => {
    if (confirm) {
      handleConfirm(e);
    } else {
      handleCancel(e);
    }
  }

  return (
    <Box>
      <Title>Continuar para...</Title>
      <span>"{redirectUri}"</span><br/><br/>
      <span>Confira o usuário abaixo e clique no botão "<strong>confirmar</strong>" para fazer login no ESL.</span>
      <br/>
      <br/>

      <User
        name="William Wagner"
        email="william.wagner.br@gmail.com"
      />

      <br/>
      
      <ConfirmButton confirmText="Confirmar" cancelText="Cancelar" onClick={handleConfirmClick} disabled={false} />
      {/*
      <div>
        <Button onClick={handleConfirm}>
          <ButtonContent>
            <ConfirmIcon />
            Confirmar
          </ButtonContent>
        </Button>
        <span>&nbsp;ou&nbsp;</span>
        <Button color="danger" onClick={handleCancel}>
          <ButtonContent>
            <CancelIcon />
            Cancelar
          </ButtonContent>
        </Button>
      </div>
      */}
    </Box>
  )
}

export default withRouter(InvitationPage);