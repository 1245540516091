import React, { memo } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';

import { Box } from '../../components/Whitebox';
import AuthGateway from '../../components/AuthGateway';

import Signed from './Signed';
import Unsigned from './Unsigned';

/*
if (this.state.loading) {
      return (
        <>
          <Title>Carregando...</Title>
        </>
      )
    }

    if (this.state.error) {
      return (
        <>
          <Title>Oops! algo de errado</Title>
          <span>{this.state.error.toString()}</span>
        </>
      )
    }

    if (this.props.data.invitation.error) {
      return (<p>Convite não encontrado</p>);
    }

    if (this.props.data.project.error) {
      return (<p>Projeto não encontrado</p>);
    }
*/

/**
 * Load invite data
 * @param {String} code 
 * @param {Object} api 
 */
async function loadInviteData (invitationId) {
  try {
    var invitationResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/invitations/${invitationId}`);

    if (invitationResponse.data.error) {
      throw new Error(invitationResponse.data.error);
    }

    const { projectId, email, requesterId } = invitationResponse.data.data;

    var requesterResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/${requesterId}`);
    var projectResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/projects/${projectId}`);
    var isUserAlreadyRegistred = await checkUserExists(email);
    
    return {
      error: null,
      data: {
        invitation: invitationResponse.data,
        project: projectResponse.data,
        requester: requesterResponse.data,
        isUserAlreadyRegistred
      }
    }
  } catch (e) {
    return {
      error: e
    }
  }
}

/**
 * Check user exists
 * @param {String} username
 * @returns {Promise<Boolean>}
 */
function checkUserExists (username) {
  return new Promise((resolve, reject) => {
    Auth.signIn(username, '123')
      .then(() => {
        resolve(true); // Fallback
      })
      .catch((err) => {
        if (err.code === 'UserNotFoundException') {
          return resolve(false);
        }

        if (err.code === 'NotAuthorizedException') {
          return resolve(true);
        }

        reject(err);
      });
  });
}

const InvitationGateway = memo((props) => {
  const { id, invitation, token } = props;

  if (invitation.loading) {
    return (<span>Carregando convite...</span>);
  }

  if (invitation.error) {
    return (<span>Falha ao carregar convite</span>);
  }

  if (invitation.data.invitation.error) {
    return (<p>Convite não encontrado</p>);
  }

  if (invitation.data.project.error) {
    return (<p>Projeto não encontrado</p>);
  }
  
  /*
  if (!invitation.data.invitation.open) {
    return (<p>Convite já usado</p>);
  }
  */ 

  return (
    <Box>
      <AuthGateway login={(props) => (<Unsigned {...props} id={id} token={token} data={invitation.data} />)}>
        <Signed id={id} token={token} data={invitation.data} />
      </AuthGateway>
    </Box>
  );
});

class InvitationLoader extends React.PureComponent {

  state = {
    loading: true,
    error: null,
    data: {}
  }

  componentDidMount () {
    this.fetchData();
  }

  componentDidUpdate (prevProps) {
    //prevProps
  }

  fetchData = () => {
    loadInviteData(this.props.id)
      .then((response) => {
        if (response.error) {
          this.setState({
            loading: false,
            error: response.error,
            data: {}
          });
        } else {
          this.setState({
            loading: false,
            error: null,
            data: response.data
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error,
          data: {}
        });
      });
  }

  render () {
    return (
      <InvitationGateway
        id={this.props.id}
        token={this.props.code}
        invitation={{ ...this.state }}
      />
    );
  }
}

const InvitationPage = memo(({ match, location }) => {
  const { id } = match.params;
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');

  if (!code) {
    return (<p>Código não definido</p>);
  }

  return (<InvitationLoader id={id} code={code} />)
});

export default InvitationPage;