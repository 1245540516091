import React, { useState } from 'react';

import SignInForm from './SignInForm';
import VerifyForm from '../VerifyUserForm';

const InvitationSignInForm = (props) => {
  const [ authenticated, setAuthenticated ] = useState(false);
  const [ confirmed, setConfirmed ] = useState(false);

  const handleCancel = (e) => {
    if (props.onCancel) {
      props.onCancel(e);
    }
  }

  /**
   * Handle login success response
   * @param {*} data 
   */
  const handleSignInSuccess = (data) => {
    console.log('Sign in success', data);
    setAuthenticated(true);
    setConfirmed(true);
    props.onSuccess(data);
    // User not confirmed -> Confirmation challenge
    // Done!
  }

  /**
   * Handle account verify success response
   * @param {*} data 
   */
  const handleVerifySuccess = (data) => {
    console.log('Verify success', data);
    // TODO: Redo login (username, password)
    // Done!
  }

  if (!authenticated) {
    return (
      <SignInForm
        data={props.data}
        onSuccess={handleSignInSuccess}
        onCancel={handleCancel}
      />
    )
  }

  if (!confirmed) {
    return (
      <VerifyForm
        username={props.data.email}
        onSuccess={handleVerifySuccess}
        onCancel={handleCancel}
      />
    )
  }

  return (<p>Usuário logado com sucesso!</p>);
}

export default InvitationSignInForm;