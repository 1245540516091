import React from 'react';
import ErrorMessage from '../ErrorSpan';

const TextField = (props) => {
  var hasError = props.error && props.error.length > 0;

  const renderHelp = () => {
    if (hasError) {
      return (<ErrorMessage message={props.error} />)
    }

    return null;
  }

  return (
    <div className={`form-item ${hasError ? 'has-error' : ''}`}>
      <div className="form-group">
        <label className="form-label">{props.label}</label>
        <div className="form-control">
          <input
            name={props.name || props.key }
            type={props.type ? props.type : 'text'}
            value={props.value}
            onChange={props.onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            disabled={Boolean(props.disabled)}
          />
        </div>
      </div>
      { renderHelp() }
    </div>
  )
}

export default TextField;