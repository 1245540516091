import React from 'react';
//import analytics from '../../analytics';

class Analytics extends React.Component {
  render () {
    /*
    analytics.event({
      category: this.props.category,
      action: this.props.action
    });
    */
    return null;
  }
}

export default Analytics;