import React from 'react';
import { Auth } from 'aws-amplify';
import validator from 'validator';
import { withFormik } from 'formik';

import TextField from '../TextField';
import Button from '../Button';
import { Body, Footer, Title } from '../Whitebox';

function getFieldValue (field, data) {
  if (!data[field]) {
    return null;
  }

  return data[field];
}

/**
 * Validation: not empty
 * @param {String} field
 * @returns {Function} validator
 */
function validateNonNull (field) {
  return (data) => {
    var value = getFieldValue(field, data);

    if (!value || value.length < 1) {
      return 'Por favor, preencha este campo';
    }
  };
}

/**
 * Validate match
 * @param {String} fieldA
 * @param {String} fieldB 
 * @returns {Function} validator
 */
function validateMatch (fieldA, fieldB) {
  return (values) => {
    var a = getFieldValue(fieldA, values);
    var b = getFieldValue(fieldB, values);

    if (a === b) {
      return null;
    }

    return 'As senhas não coincidem';
  }
}

/**
 * Validation: minimum password length
 * @param {String} value
 * @param {Number} min
 * @returns {Function} validator
 */
const validateCharLength = (field, min) => {
  return (data) => {
    var value = getFieldValue(field, data);

    if (!value || value.length < min) {
      return `Por favor, use ${min} ou mais caracteres`;
    }
  
    return null;
  }
}

/**
 * Validattion: email address
 * @param {String} field
 * @returns {Function} validator
 */
const validateEmail = (field) => {
  return (data) => {
    var value = getFieldValue(field, data);

    if (!validator.isEmail(value)) {
      return 'Informe um endereço de e-mail válido';
    }

    return null;
  }
}

/**
 * Combine validators
 * @param {Array<Function>} validators 
 * @returns {Function} validator
 */
const combineValidators = (validators) => {
  return (data) => {
    var errors = [];

    validators.forEach((validator) => {
      var error = validator(data);

      if (error) {
        if (Array.isArray(error)) {
          error.forEach(errors.push);
        } else {
          errors.push(error);
        }
      }
    });

    if (errors.length === 0) {
      return null;
    }

    return errors;
  }
}

/**
 * Validate form
 * @param {Object} validators 
 * @param {Object} values 
 */
const validateForm = (validators, values) => {
  var buffer = {};

  Object.keys(validators).forEach((field) => {
    var validator = validators[field];
    var errors = validator(values);

    if (errors) {
      buffer[field] = errors;
    }
  });

  return buffer;
}

const SignUpForm = (props) => {
  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    handleBlur,
    handleChange,
    setSubmitting,
    setStatus
  } = props;

  /**
   * Handle form success response
   */
  const handleSuccess = () => {
    if (props.onSuccess) {
      props.onSuccess(values);
    }
  }

  /**
   * Handle form error response
   * @param {*} error 
   */
  const handleError = (error) => {
    if (props.onError) {
      props.onError(error, values);
    }
  }

  /**
   * Handle user cancel
   * @param {Event} e 
   */
  const handleCancel = (e) => {
    if (props.onCancel) {
      props.onCancel(e);
    }
  }

  /**
   * Handle form submit
   * @param {Event} e
   */
  const handleSubmit = (e) => {
    setStatus({ error: null });
    setSubmitting(true);

    const email = values.email.toLowerCase();

    var signUpParams = {
      username: email,
      password: values.password,
      attributes: {
        name: values.name,
        email: email
      }
    };

    Auth.signUp(signUpParams)
      .then(() => {
        setStatus({ error: null });
        handleSuccess();
      })
      .catch((error) => {
        setStatus({ error: error.message || error.toString() })
        handleError(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <>
      <Title>Criar uma conta no Service Location</Title>
      <Body>
        <TextField
          name="email"
          label="Email"
          type="email"
          value={values.email}
          disabled={true}
        />

        <TextField
          name="name"
          label="Nome"
          value={values.name}
          error={touched.name && errors.name}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <TextField
          name="password"
          label="Senha"
          type="password"
          value={values.password}
          error={touched.password && errors.password}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <TextField
          name="confirmPassword"
          label="Confirmar"
          type="password"
          value={values.confirmPassword}
          error={(touched.password || touched.confirmPassword) && errors.confirmPassword}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        <p>{props.status.error}</p>

        <Footer style={{padding: '4px'}}>
          <Button color="primary" type="button" onClick={handleSubmit} disabled={!isValid}>{ isSubmitting ? 'Cadastrando...' : 'Cadastrar' }</Button>
          <Button color="secondary" type="button" onClick={handleCancel}>Voltar</Button>
        </Footer>
      </Body>
    </>
  );
}

export default withFormik({
  mapPropsToValues: (props) => {
    return props.data;
  },
  mapPropsToStatus: (props) => {
    return { error: null };
  },
  validate: (values) => {
    return validateForm({
      'name': validateNonNull('name'),
      'email': combineValidators([
        validateNonNull('email'),
        validateEmail('email')
      ]),
      'password': combineValidators([
        validateNonNull('password'),
        validateCharLength('password', 6)
      ]),
      'confirmPassword': combineValidators([
        validateNonNull('confirmPassword'),
        validateMatch('confirmPassword', 'password')
      ])
    }, values);
  },
  validateOnBlur: true
})(SignUpForm);
