import React from 'react';

import api from '../api';
import session from '../Session';

const withApi = (Component) => {
  return (props) => {
    const handleApiSigned = (cb) => {
      session.getCredentials()
        .then((credentials) => {
          api.setToken(credentials.jwt.idToken);
          cb(null, api);
        })
        .catch(cb)
    }

    return (<Component {...props} api={api} apiSigned={handleApiSigned} />)
  }
}

export default withApi;