import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgba(0,0,0,0.025);
  border-radius: 4px;
  padding: 14px 7px;
  border: 1px solid #ccc;
  margin: 8px 0px;

  & img {
    min-width: 40px;
    min-height: 40px;
    border: 2px solid #ccc;
    border-radius: 50%;
  }

  & .user-avatar-info {
    margin-left: 8px;
  }

  & .user-avatar-signout {
    font-size: 14px;
    color: #0084EE;
    outline: none;
    border: none;
    background: none;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
  }
`

const User = (props) => {
  return (
    <Container>
      <img
        src={`https://ui-avatars.com/api/?name=${props.name}&font-size=0.33&rounded=true`}
        alt={`Avatar do usuário "${props.name}"`}
        title={`Avatar do usuário "${props.name}"`}
        width='40'
        height='40'
      />
      <div className="user-avatar-info">
        <span>{props.email}</span><br/>
        <button type="button" className="user-avatar-signout" onClick={props.onSignOut}>Sair da conta</button>
      </div>
    </Container>
  )
}

export default User;