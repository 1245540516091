import Amplify from './Amplify';
import { BehaviorSubject } from 'rxjs';

const { Auth } = Amplify;

const DEFAULT_STATE = {
  loading: true,
  connected: false, // Is signed or not
  emailConfirmed: false,
  passwordRequired: false,
  error: null,
  user: null,
  userCognito: null,
  signIn: {
    loading: false,
    error: null
  }
}

const parseCognitoUser = (cognitoUser) => {
  return {
    id: cognitoUser.attributes.sub,
    name: cognitoUser.attributes.name,
    email: cognitoUser.attributes.email,
    verified: cognitoUser.attributes.email_verified,
    jwt: {
      idToken: cognitoUser.signInUserSession.idToken.jwtToken,
      refreshToken: cognitoUser.signInUserSession.refreshToken.token,
    }
  }
}

class UserSession {

  constructor () {
    this.state = DEFAULT_STATE;
    this.observable = new BehaviorSubject(DEFAULT_STATE);
    this.timeoutId = null;
  }

  _notifyUpdate = () => {
    this.observable.next(this.state);
  }

  setUser = (cognitoUser) => {
    if (!cognitoUser) {
      this.state.connected = false;
      this.state.user = null;
      this.state.userCognito = null;
    } else {
      this.state.connected = true;
      this.state.user = parseCognitoUser(cognitoUser);
      this.state.userCognito = cognitoUser;
    }
  }

  /**
   * Sign-in user
   * @param {String} username
   * @param {String} password
   * @param {Boolean} notifyChange
   */
  signIn = async (username, password, notifyChange = true) => {
    if (notifyChange) {
      this.state.signIn.loading = true;
      this.state.signIn.error = null;
      this._notifyUpdate();
    }

    try {
      var user = await Auth.signIn(username, password);
      this.state.signIn.loading = false;

      if (notifyChange) {
        this.state.connected = true;
        this.state.emailConfirmed = true;
        this.state.passwordRequired = false;
        this.setUser(user);
      }

      return user;

    } catch (err) {
      if (notifyChange) {
        this.state.signIn.loading = false;
        this.state.signIn.error = err;
        this.state.connected = false;
        this.state.loading = false;
        this.setUser(null);
      }

      // TODO: email confirmed?
      // TODO: password required?
      throw err;
    } finally {
      if (notifyChange) {
        this._notifyUpdate();
      }
    }
  }

  /**
   * Sign-out current user
   */
  signOut = () => {
    Auth.signOut();
    this.state.connected = false;
    this._notifyUpdate();
  }

  /**
   * Refresh session
   */
  auth = () => {
    this.state.loading = true;
    this.state.connected = false;
    this.state.error = null;
    this._notifyUpdate();

    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.state.loading = false;
        this.state.connected = true;
        this.state.error = null;
        this.setUser(user);
        this._notifyUpdate();
      })
      .catch((err) => {
        this.state.loading = false;
        this.state.connected = false;
        this.state.error = null;
        this.setUser(null);

        if (err !== 'not authenticated') {
          this.state.error = err;
        }

        this._notifyUpdate();
      });
  }

  /**
   * Subscribe to changes
   * @param {Function} obs Observer
   * @returns Subscription
   */
  subscribe = (obs) => {
    return this.observable.subscribe(obs);
  }
}

export default new UserSession();