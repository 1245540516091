//import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
//import { put, call, select } from 'redux-saga/effects';

/*
cognitoUser.getAttributeVerificationCode('email', {
    onSuccess: function(result) {
        console.log('call result: ' + result);
    },
    onFailure: function(err) {
        alert(err.message || JSON.stringify(err));
    },
    inputVerificationCode: function() {
        var verificationCode = prompt('Please input verification code: ', '');
        cognitoUser.verifyAttribute('email', verificationCode, this);
    },
});
*/

function* confirmUser (action, state) { 
};

export default confirmUser;