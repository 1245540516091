//import axios from 'axios';
//import { put, call, select } from 'redux-saga/effects';

function* fetchInvitation (action) {

  /*
  var stateUsers = yield select(state => state.users);
  var { currentUserId } = stateUsers;

  yield put({
    type: 'USERS.SET_PROJECTS_RESPONSE',
    value: {
      user: currentUserId,
      response: {
        fetched: false,
        error: null
      }
    }
  });

  try {
    var user = stateUsers.data[currentUserId];
    var response = yield call(loadProjectsApi, user.token);

    yield put({
      type: 'USERS.SET_PROJECTS',
      value: {
        user: currentUserId,
        projects: Object.fromEntries(response.map((project) => {
          return [project.id, project];
        }))
      }
    });

    yield put({
      type: 'USERS.SET_PROJECTS_RESPONSE',
      value: {
        user: currentUserId,
        response: {
          fetched: true,
          error: null
        }
      }
    });
  } catch (e) {
    yield put({
      type: 'USERS.SET_PROJECTS_RESPONSE',
      value: {
        user: currentUserId,
        response: {
          fetched: true,
          error: e.message
        }
      }
    });
  }
  */
};

export default fetchInvitation;