import React from 'react';
import Button from '../Button';
import ButtonContent from '../ButtonContent';
import PropTypes from 'prop-types';

/**
 * Confirm button
 * @param {*} props
 */
const ConfirmButton = (props) => {
  
  /**
   * Factory a click handler
   * @param {Boolean} confirm 
   * @returns {Function} Click handler
   */
  const handleClick = (confirm) => {
    
    /**
     * Click handler
     * @params {Event} e
     */
    return (e) => {
      if (props.onClick) {
        props.onClick(confirm, e);
      }
    }
  }

  return (
    <div>
      <Button onClick={handleClick(true)} disabled={props.disabled}>
        <ButtonContent>
          {/*<ConfirmIcon />*/}
          { props.confirmText || 'Confirmar' }
        </ButtonContent>
      </Button>
      <span>&nbsp;ou&nbsp;</span>
      <Button color="danger" onClick={handleClick(false)} disabled={props.disabled}>
        <ButtonContent>
          {/*<CancelIcon />*/}
          { props.cancelText || 'Cancelar' }
        </ButtonContent>
      </Button>
    </div>
  )
}

ConfirmButton.propTypes = {
  disabled: PropTypes.bool
}

export default ConfirmButton;