import React from 'react';
import TextField from '../TextField';

class EmailField extends React.Component {

  /**
   * Return value normalized
   * @param {String} value
   * @returns {String}
   */
  getValueNormalized = (value) => {
    if (typeof value === 'string') {
      return value.toLowerCase();
    }

    return value;
  }

  /**
   * Handle change
   * @param {Event} e
   */
  handleChange = (e) => {
    if (e.target.value) {
      e.target.value = e.target.value.toLowerCase();
    }

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render () {
    return (
      <TextField
        {...this.props}
        value={this.getValueNormalized(this.props.value)}
        onChange={this.handleChange}
      />
    )
  }
}

export default EmailField;