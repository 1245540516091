import React from 'react';
import { Auth } from 'aws-amplify';
import { withFormik } from 'formik';

import TextField from '../TextField';
import Button from '../Button';
import { Title, Footer } from '../Whitebox';

/**
 * Return text for common error codes
 * @param {String} code 
 * @returns {String}
 */
const translateError = (code) => {
  if (!code) {
    return null;
  }

  if (code === 'not_found') {
    return 'Usuário não encontrado';
  }

  if (code === 'not_authorized') {
    return 'Usuário ou senha inválidos';
  }

  if (code === 'not_confirmed') {
    return 'Confirmação pendente';
  }

  if (code === 'change_password_required') {
    return 'Alteração de senha obrigatória';
  }

  if (code === 'max_attempts_exceeded') {
    return 'Número máximo de tentativas ultrapassado!';
  }

  return 'Oops! algo deu errado';
}

/**
 * Parse amplify error
 * @param {Error} err 
 * @returns {String} error code
 */
function parseError (err) {
  var error = 'unknow';

  if (err.code === 'UserNotConfirmedException') {
    error = 'not_confirmed';
  }

  if (err.code === 'PasswordResetRequiredException') {
    error = 'change_password_required';
  }

  if (err.code === 'NotAuthorizedException') {
    if (err.message === 'Password attempts exceeded') {
      error = 'max_attempts_exceeded';
    } else {
      error = 'not_authorized';
    }
  }

  if (err.code === 'UserNotFoundException') {
    error = 'not_found';
  }

  return error;
}

const SignInForm = (props) => {
  const {
    values,
    errors,
    touched,
    status,
    handleChange,
    handleBlur,
    setStatus,
    setSubmitting,
    isSubmitting,
    isValid
  } = props;

  /**
   * Handle success response
   * @param {*} data 
   */
  const handleSuccess = (data) => {
    if (props.onSuccess) {
      props.onSuccess(data, values);
    }
  }

  /**
   * Handle error response
   * @param {*} error 
   */
  const handleError = (error) => {
    if (props.onError) {
      props.onError(error, values);
    }
  }

  /**
   * Handle form submit
   */
  const handleSubmit = () => {
    setSubmitting(true);
    setStatus({ error: null });

    Auth.signIn(values.username, values.password, false)
      .then((data) => {
        setStatus({ error: null });
        handleSuccess(data);
      })
      .catch((error) => {
        var parsedError = parseError(error);

        /*
        if (parsedError === 'not_confirmed') {
          handleSuccess(null)
        }
        */

        setStatus({ error: parsedError });
        handleError(parsedError);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  /**
   * Handle user cancellation
   * @param {Event} e 
   */
  const handleCancel = (e) => {
    if (props.onCancel) {
      props.onCancel(e);
    }
  }

  return (
    <>
      <Title>Acesse sua conta do Service Location</Title>

      <div style={{margin: '-4px', marginBottom: '8px'}}>
        <TextField
          name="username"
          label="Email"
          type="email"
          value={values.username}
          error={touched.username && errors.username}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={true}
        />

        <TextField
          name="password"
          label="Senha"
          type="password"
          value={values.password}
          error={touched.password && errors.password}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      <small style={{color: 'red'}}>{translateError(status.error)}</small>

      <Footer>
        <Button color="primary" onClick={handleSubmit} disabled={!isValid}>{ isSubmitting === false ? 'Entrar' : 'Entrando...' }</Button>
        <Button color="secondary" type="button" onClick={handleCancel}>Voltar</Button>
      </Footer>
    </>
  );
}

export default withFormik({
  mapPropsToValues: (props) => {
    return {
      username: props.data.username,
      password: ''
    }
  },
  mapPropsToStatus: () => {
    return {
      error: null
    }
  },
  validate: (values) => {
    var errors = {};

    if (!values.username || values.username.length === 0) {
      errors.username = 'O campo usuário não pode estar vazio';
    }

    if (!values.password || values.password.length === 0) {
      errors.password = 'O campo senha não pode estar vazio';
    }

    if (values.password && values.password.length < 6) {
      errors.password = 'O campo senha não pode menos que 6 caracteres';
    }

    return errors;
  },
  isInitialValid: false
})(SignInForm);