//import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import bugsnag  from '@bugsnag/js';
//import storage from '../../cookie-storage';

function* signIn (action, state) {
  try {
    //const { payload } = action;
    console.log('Signing in...', action);

    /*
    //var storage = new AmazonCognitoIdentity.CookieStorage({ domain: process.env.REACT_APP_COOKIE_DOMAIN, secure: false });

    var userPoolParams = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
      Storage: storage
    };

    var userPool = new AmazonCognitoIdentity.CognitoUserPool(userPoolParams);

    var cognitoUserParams = {
      Username: payload.username,
      Pool: userPool,
      Storage: storage
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(cognitoUserParams);

    console.log('cognitoUser', cognitoUser);

    var authenticationParams = {
      Username: payload.username,
      Password: payload.password
    };

    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationParams);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        //const userId = result.idToken.payload.sub;
        //const appId = result.idToken.payload.aud;
        var accessToken = result.getAccessToken().getJwtToken();
        var idToken = result.getIdToken().getJwtToken();
        console.log('success', { accessToken, idToken });
      },
      onFailure: (error) => {
        // Code here
        console.log('error', error);
      }
    });
    */
  } catch (e) {
    bugsnag.notify(e);
  }

  yield null;
};

export default signIn;