import React from 'react';
import PropTypes from 'prop-types';

const getColorClassName = (color) => {
  if (color) {
    return color;
  }

  return 'primary';
}

const Button = (props) => {
  var size = props.size || 'normal';

  var classes = [
    'btn',
    `btn-${getColorClassName(props.color)}`,
    `btn-${size}`
  ];

  if (props.disabled) {
    classes.push('disabled');
  }

  const handleClick = (e) => {
    if (props.disabled) {
      return false;
    }

    if (!props.onClick) {
      return false;
    }

    props.onClick(e);
  }

  return (
    <button type="button" {...props} className={classes.join(' ')} onClick={handleClick}>
      {props.children}
    </button>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool
}

export default Button;