import React from 'react';

import SignUpForm from './SignUpForm';
import VerifyForm from '../VerifyUserForm';

class InvitationSignUpForm extends React.PureComponent {

  constructor (props) {
    super(props);

    this.state = {
      created: false,
      confirmed: false
    }
  }

  handleSignUpSuccess = (data) => {
    console.log('Sign up success', data);
    this.setState({ created: true });
  }

  handleSignUpError = (err) => {
    console.log('Sign up error', err);
  }

  handleSignUpCancel = (info) => {
    console.log('Sign up cancel', info);
    this.props.onCancel(info);
  }

  handleVerifySuccess = (data) => {
    console.log('Verify account success', data);
  }

  handleVerifyError = (err) => {
    console.log('Verify account error', err);
  }

  handleVerifyCancel = (info) => {
    console.log('Verify account cancel', info);
    this.props.onCancel(info);
  }

  render () {
    if (!this.state.created) {
      return (
        <SignUpForm
          data={this.props.data}
          onSuccess={this.handleSignUpSuccess}
          onError={this.handleSignUpError}
          onCancel={this.handleSignUpCancel}
        />
      );
    }

    if (!this.state.confirmed) {
      return (
        <VerifyForm
          username={this.props.data.email}
          onSuccess={this.handleVerifySuccess}
          onError={this.handleVerifyError}
          onCancel={this.handleVerifyCancel}
        />
      );
    }

    return (<span>Conta cadastrada com sucesso!</span>);
  }
}

export default InvitationSignUpForm;