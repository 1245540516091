const DEFAULT_STATE = {
  loading: false,
  connected: false,
  data: null
}

function userReducer (state = DEFAULT_STATE, action) {
  if (action.type === 'USER.FETCH_OK') {
    return {
      ...state,
      loading: false,
      connected: true,
      data: action.payload
    }
  }

  if (action.type === 'USER.FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      connected: false,
      data: null
    }
  }

  if (action.type === 'USER.SET_DATA') {
    return { ...action.payload };
  }

  return state;
}

export default userReducer;