import React from 'react';
import { Title, Footer } from '../Whitebox';
import TextField from '../TextField';
import EmailField from '../EmailField';
import Button from '../Button';
import Link from '../ButtonLink';
import VerifyAccountForm from '../VerifyUserForm';
import withUser from '../withUser';
import withAnalytics from '../withAnalytics';
import withBugsnag from '../withBugsnag';
import PropTypes from 'prop-types';
//import store from '../../store';

/**
 * Return text for common error codes
 * @param {String} code 
 * @returns {String}
 */
const getErrorTranslation = (code) => {
  if (!code) {
    return null;
  }

  if (code === 'not_found') {
    return 'Usuário não encontrado';
  }

  if (code === 'not_authorized') {
    return 'Acesso negado';
  }

  if (code === 'not_confirmed') {
    return 'Confirmação pendente';
  }

  if (code === 'change_password_required') {
    return 'Alteração de senha obrigatória';
  }

  return 'Oops! algo deu errado';
}

class SignInForm extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      data: {
        username: this.props.username || '',
        password: '',
        step: 'password'
      },
      loading: false,
      error: null,
      challenge: null
    }
  }

  /**
   * Handle sign-in click
   * @param {Event} e
   */
  handleSignIn = (e) => {
    if (!this.state.data.username) {
      return false;
    }

    if (!this.state.data.password) {
      return false;
    }

    if (this.state.loading) {
      return false;
    }

    if (this.props.onSignIn) {
      return this.props.onSignIn({
        username: this.state.data.username,
        password: this.state.data.password
      });
    }

    this.handleSignInDefault(e);
  }

  handleSignInDefault = (e) => {
    this.setState({
      loading: true,
      error: null
    });

    /*
    store.dispatch({
      type: 'SIGN_IN',
      payload:  {
        username: this.state.data.username,
        password: this.state.data.password
      }
    });
    */
    
    this.props.authUser.signIn(this.state.data.username, this.state.data.password, Boolean(this.props.notifySessionChanges))
      .then(this.handleSignedIn)
      .catch((err) => {
        var error = 'unknow';        

        if (err.code === 'UserNotConfirmedException') {
          this.setState({
            loading: false,
            error: false,
            challenge: 'confirm_account'
          });

          return false;
        }

        if (err.code === 'PasswordResetRequiredException') {
          error = 'change_password_required';
        }

        if (err.code === 'NotAuthorizedException') {
          error = 'not_authorized';
        }

        if (err.code === 'UserNotFoundException') {
          error = 'not_found';
        }

        this.props.bugsnag.notify(error);

        this.props.analytics.event({
          category: 'Sign in',
          action: 'Sign in (error)',
          value: error.toString()
        });

        this.setState({
          loading: false,
          error: error
        });
      });    
  }
  
  handleSignedIn = (user) => {
    this.props.analytics.event({
      category: 'Sign in',
      action: 'Sign in (success)',
      value: this.state.data.username
    });

    if (this.props.onSignedIn) {
      return this.props.onSignedIn(user);
    }
  }

  /**
   * Handle sign-up click
   * @param {Event} e
   */
  getSignUpUrl = (e) => {
    var url = '/#/sign-up';
    var queryStrings = new URLSearchParams();

    if (this.props.redirectUri && this.props.redirectUri !== '/') {
      queryStrings.append('redirectUri', this.props.redirectUri);
    }

    if (this.props.username) {
      queryStrings.append('email', this.props.username);
    }

    url = `${url}?${queryStrings.toString()}`;
    //window.location.href = url;
    return url;
  }

  /**
   * On field change
   * @param {String} field
   * @returns {Function} handler
   */
  handleFieldChange = (field) => {
    return (e) => {
      var newData = { ...this.state.data };
      newData[field] = e.target.value;
      this.setState({ data: newData });
    }
  }

  /**
   * On field blur
   * @param {String} field
   * @returns {Function} handler
   */
  handleFieldBlur = (field) => {
    return (e) => {
      /*
      var errors = { ...this.state.validationErrors };
      var value = this.state.data[field] || '';

      if (field === 'name') {
        errors[field] = validateNotEmpty(value);
      }

      if (field === 'email') {
        errors[field] = validateNotEmpty(value) || validateEmailAddress(value);
      }

      if (field === 'password') {
        errors[field] = validateNotEmpty(value) || validatePasswordLength(value, 6);
      }

      if (field === 'passwordConfirm') {
        errors[field] = validateNotEmpty(value) || validateConfirmPassword(value, this.state.data.password);
      }

      this.setState({
        validationErrors: errors
      });
      */
    }
  }

  /**
   * Get validation error for field
   * @param {String} field
   * @returns {String|undefined}
   */
  getValidationErrorOf = (field) => {
    //return this.state.validationErrors[field] || undefined;
    return undefined;
  }
  
  renderSignUpButton = () => {
    if (Boolean(this.props.displaySignUp) && false) {
      return (
        <Link color="secondary" href={this.getSignUpUrl()}>
          Cadastre-se
        </Link>
      )
    }

    return null;
  }  

  renderVerifyAccount = () => {
    return (
      <VerifyAccountForm
        username={this.state.data.username}
        onSuccess={(e) => {
          this.setState({
            challenge: null
          });

          this.handleSignIn();
        }}
      />
    )
  }

  render () {
    if (this.state.challenge === 'confirm_account') {
      return this.renderVerifyAccount();
    }

    return this.renderForm();
  }

  renderForm () {
    return (
      <>
        <Title>Acesse sua conta do Service Location</Title>

        <div style={{margin: '-4px', marginBottom: '8px'}}>
          <EmailField
            label="Email"
            type="email"
            value={this.state.data.username}
            onChange={this.handleFieldChange('username')}
            error={this.getValidationErrorOf('username')}
            onBlur={this.handleFieldBlur('username')}            
          />

          <TextField
            label="Senha"
            type="password"
            value={this.state.data.password}
            onChange={this.handleFieldChange('password')}
            error={this.getValidationErrorOf('password')}
            onBlur={this.handleFieldBlur('password')}            
          />
        </div>
        <small style={{color: 'red'}}>{getErrorTranslation(this.state.error)}</small>

        <Footer>
          <Button color="primary" onClick={this.handleSignIn}>
            { this.state.loading === false ? 'Entrar' : 'Entrando...' }
          </Button>

          {this.renderSignUpButton()}
        </Footer>
      </>
    );
  }
}

SignInForm.propTypes = {
  displaySignUp: PropTypes.bool,
  notifySessionChanges: PropTypes.bool,
  username: PropTypes.string
}

export default withBugsnag(withAnalytics(withUser(SignInForm)));