import Amplify from './Amplify';
//import { BehaviorSubject } from 'rxjs';

const { Auth } = Amplify;
const TTL = 1 * 60000; // 15 minutes

/**
 * Parse cognito user
 * @param {Object} cognitoUser 
 * @returns {Object}
 */
const parseCognitoUser = (cognitoUser) => {
  if (!cognitoUser) {
    return null;
  }

  return {
    id: cognitoUser.attributes.sub,
    name: cognitoUser.attributes.name,
    email: cognitoUser.attributes.email,
    verified: cognitoUser.attributes.email_verified,
    jwt: {
      idToken: cognitoUser.signInUserSession.idToken.jwtToken,
      refreshToken: cognitoUser.signInUserSession.refreshToken.token,
    }
  }
}

class AuthSession {

  constructor () {
    this.refreshedAt = null;
    this.credentials = null;

    this._autoRefreshId = null;
    this._autoRefreshMs = null;
    this._autoRefreshStartup = false;
  }

  /**
   * Get current user
   * @returns {Promise<Object>}
   */
  getCredentials = async () => {
    var now = (new Date()).getTime();

    if (!this.refreshedAt) {
      await this._syncCredentials();
    }

    if (this.refreshedAt) {
      var delayedTime = now - this.refreshedAt;

      if (delayedTime > TTL) {
        await this._syncCredentials();
      }
    }

    return this._getCredentials();
  }

  /**
   * Clear stored values
   */
  clear = () => {
    this.refreshedAt = null;
    this.credentials = null;

    this.clearAutoRefresh();

    if (this._autoRefreshMs) {
      this.autoRefresh(this._autoRefreshMs, this._autoRefreshStartup);
    }
  }

  /**
   * Returns stored credentials
   * @returns {Object}
   */
  _getCredentials = () => {
    return this.credentials;
  }

  /**
   * Syncronize credentials
   * @returns {undefined}
   */
  _syncCredentials = async () => {
    this.refreshedAt = (new Date()).getTime();
    var cognitoUser = await this._getSession();
    this.credentials = parseCognitoUser(cognitoUser);
  }

  /**
   * Refresh session
   */
  _getSession = () => {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          resolve(user);
        })
        .catch((err) => {
          resolve(null);
        });
    });
  }

  /**
   * Auto refresh user credentials
   * @param {Number} ms Milliseconds
   * @param {Boolean} autoStart
   */
  autoRefresh = (ms, autoStart = true) => {
    this._autoRefreshMs = ms;
    this._autoRefreshStartup = autoStart;

    const refreshFn = () => {
      this.getCredentials()
        .then(() => {
          console.log('Credentials updated');
        })
        .catch((e) => {
          console.log('Credentials error', e);
        });
    }

    if (autoStart) {
      refreshFn(); // auto start
    }

    this._autoRefreshId = setInterval(refreshFn, ms);
  }

  /**
   * Clear auto refresh
   */
  clearAutoRefresh = () => {
    if (this._autoRefreshId) {
      clearInterval(this._autoRefreshId);
    }
  }
}

export default new AuthSession();