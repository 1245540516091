import React from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import { Provider } from 'react-redux';

import store from './store';
import session from './UserSession';
import sessionStore from './Session';
import api from './api';
import './analytics';
import './Amplify';

import Analytics from './components/Analytics';
import HomePage from './pages/Home';
import SignInPage from './pages/SignIn';
import SignUpPage from './pages/SignUp';
import InvitationPage from './pages/Invitation';
import OAuthPage from './pages/OAuth';
import DisconnectPage from './pages/Disconnect';

import Cookies from 'js-cookie';

session.auth();
sessionStore.autoRefresh(15 * 60 * 1000) // Refresh every 15 minutes

session.subscribe((credentials) => {
  // Clean JWT tokens
  sessionStore.clear();

  // Update user store
  store.dispatch({
    type: 'USER.SET_DATA',
    payload: {
      loading: credentials.loading,
      connected: credentials.connected,
      data: credentials.user
    }
  });
});

//store.dispatch({ type: 'INITIALIZE' });

window.api = api;
window.store = store;
window.Cookies = Cookies;

function App () {
  return (
    <Provider store={store}>
      <Analytics category="View" action="View auth application" />
      <Router>
        <Route path="/" exact component={HomePage} />
        <Route path="/sign-in" exact component={SignInPage} />
        <Route path="/sign-up" exact component={SignUpPage} />
        <Route path="/invitation/:id" exact component={InvitationPage} />
        <Route path="/oauth" exact component={OAuthPage} />
        <Route path="/disconnect" exact component={DisconnectPage} />
      </Router>
    </Provider>
  )
}

export default App;