import styled from 'styled-components';

export const Link = styled.button`
  border: none;
  background: none;
  color: rgb(0, 0, 238);
  text-decoration: underline;
  display: inline;
  align-items: center;
  cursor: pointer;
`

export const Input = styled.input`
  width: 100%;
  padding: 7px;
  min-height: 35px;
  border-radius: 3px;
  border: 1px solid #C8CED3;
  background-color: #ffffff;
  color: #73818F;
  box-sizing: border-box;
  font-family: Raleway, sans-serif;
  font-size: 15px;
  margin: 3px 0px;
  margin-bottom: 5px;

  &[disabled] {
    background-color: #f1f1f1;
    cursor: default;
  }
`