import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Title } from '../../../components/Whitebox';
import Button from '../../../components/Button';
import { Uncontrolled as User } from '../../../components/User';
import withUser from '../../../components/withUser';
import withApi from '../../../components/withApi';

import { Warning } from './styles';

const INVITATION_CONFIRM = true;
//const INVITATION_REFUSE = false;

/**
 * Return permission as human friendly text
 * @param {String} key 
 * @returns {String}
 */
const getTranslatedPermission = (key) => {
  var mapping = {
    'READ': 'Somente leitura',
    'WRITE': 'Escrita',
    'ADMIN': 'Gerenciamento',
    'OWNER': 'Proprietário'
  }

  return mapping[key] || 'Permissão não reconhecida';
}

class Signed extends React.PureComponent {

  constructor (props) {
    super(props);

    this.state = {
      sending: false,
      error: null,
      data: null
    }
  }

  _completeInvitation = (answer, callback) => {
    const { id, token } = this.props;

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_ENDPOINT}/graphql/user`,
      json: true,
      headers: {
        'Authorization': `Bearer ${this.props.authSession.credentials.jwt.idToken}`
      },
      data: {
        query: `
          mutation invitationComplete ($input: InvitationCompleteInput!) {
            invitationComplete (input: $input) {
              error
              data {
                projectId
              }
            }
          }
        `,
        variables: {
          input: {
            id: id,
            token: token,
            confirm: INVITATION_CONFIRM
          }
        }
      }
    })
    .then((httpResponse) => {
      const { invitationComplete } = httpResponse.data.data;

      if (invitationComplete.error) {
        return callback(invitationComplete.error);
      }

      callback(null, invitationComplete.data);
    })
    .catch(callback);
  }

  /**
   * Handle confirm invitation
   */
  handleConfirm = () => {
    this.setState({
      sending: true,
      error: null
    });
    
    this._completeInvitation(INVITATION_CONFIRM, (err, response) => {
      if (err) {
        return this.setState({
          sending: false,
          error: err.message || err.toString(),
          data: null
        });
      }

      this.setState({
        sending: false,
        error: null,
        data: response
      });
    });
  }

  isValidRequester = () => {
    var userId = this.props.user.data.id;
    var requesterId = this.props.data.invitation.data.requesterId;

    if (!requesterId) {
      return false;
    }

    if (!userId) {
      return false;
    }

    return requesterId !== userId;
  }

  getConfirmText = () => {
    if (this.state.sending) {
      return 'Aceitando...';
    }

    return 'Aceitar';
  }

  render () {
    return (
      <>
        <Title>Você recebeu um convite</Title>
        <span>Você foi convidado a participar do projeto "<strong>{this.props.data.project.data.name}</strong>"</span>
        <br/>
        <br/>

        <span>De: {this.props.data.requester.data.email}</span><br/>
        <span>Permissão: {getTranslatedPermission(this.props.data.invitation.data.permission)}</span><br/>

        <br />
        <small><span>Conectado como:</span></small><br/>
        <User />

        <br/>
        { (!this.isValidRequester()) && <Warning>Alerta: Você não pode aceitar um convite que você mesmo criou.</Warning> }
        { (!this.state.sending && this.state.error) && <Warning>Erro: {this.state.error}</Warning> }

        <div>
          <Button onClick={this.handleConfirm} disabled={!this.isValidRequester() || this.state.sending}>
            {this.getConfirmText()}
          </Button>
        </div>
      </>
    )
  }
}

const withRedux = connect((state) => {
  return {
    user: state.user
  }
});

export default withRouter(withRedux(withUser(withApi(Signed))));