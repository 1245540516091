import React from 'react';
import WarningIcon from '@material-ui/icons/WarningRounded';

const ErrorMessage = (props) => {
  return (
    <small className="form-help">
      <WarningIcon />
      <span>{ props.message || '' }</span>
    </small>
  )
}

export default ErrorMessage;