import React from 'react';
import PropTypes from 'prop-types';
import Amplify from 'aws-amplify';
import RedoIcon from '@material-ui/icons/Replay';

import { Title, Footer } from '../Whitebox';
import Button from '../Button';
import withUser from '../withUser';
import { Link, Input } from './styles';

class VerifyAccountForm extends React.PureComponent {

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      resending: false,
      error: null,
      code: ''
    }
  }

  resetForm = () => {
    this.setState({
      loading: false,
      resending: false,
      error: null,
      code: ''
    });
  }

  componentDidUpdate (prevProps) {
    if (this.props.username !== prevProps.username) {
      this.resetForm();
    }
  }

  handleCodeChange = (e) => {
    this.setState({ code: e.target.value });
  }

  /**
   * Handle submit click
   * @param {Event} e
   */
  handleSubmit = (e) => {
    this.setState({
      loading: true
    });

    Amplify.Auth.confirmSignUp(this.props.username, this.state.code)
      .then((data) => {
        this.setState({
          loading: false,
          error: null
        });

        this.handleOnSuccess({ username: this.props.username, data: data });
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: 'invalid_code'
        });

        this.handleOnError(e);
      })
  }

  /**
   * Handle confirm sign-up successfully
   * @param {Object} event
   */
  handleOnSuccess = ({ username, data }) => {
    console.log(username, data);

    if (this.props.onSuccess) {
      this.props.onSuccess({ username, data });
    }
  }

  handleOnError = (e) => {
    console.log(e);

    if (this.props.onError) {
      return this.props.onError(e);
    }

    // Do nothing    
  }

  /**
   * Handle resend code
   * @param {Event} e
   */
  handleResendCode = (e) => {
    this.setState({
      resending: true
    });

    Amplify.Auth.resendSignUp(this.props.username)
      .then(() => {
        this.setState({
          resending: false
        })
      }).catch((e) => {
        console.log(e);
        this.setState({
          resending: false
        })
      });
  }

  renderErrorMessage = () => {
    if (!this.state.error) {
      return null;
    }

    return (<><small style={{color: 'red'}}>Código inválido</small><br/></>)
  }

  render () {
    return (
      <>
        <Title>Confirme sua conta</Title>
        <span>Sua conta ainda não foi confirmada, para continuar insira o código de confirmação enviado para seu email de cadastro.</span>
        <br/>
        <br/>
        
        <small>Email</small><br/>
        <Input value={this.props.username} disabled /><br/>

        <small>Código</small><br/>
        <Input
          placeholder="Digite o código"
          value={this.state.code}
          onChange={this.handleCodeChange}
          disabled={this.state.loading}
        />

        <br/>
        { this.renderErrorMessage() }
        <br/>

        <Footer>
          <Button color="primary" onClick={this.handleSubmit}>
            { this.state.loading === false ? 'Confirmar' : 'Confirmando...' }
          </Button>
        </Footer>
        <br />

        <small>
          <span>Não recebeu o código? </span>
          <Link onClick={this.handleResendCode}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <RedoIcon style={{fontSize: '14px'}} />
              <span>
                { !this.state.resending ? 'Enviar novamente' : 'Reenviando...' }
              </span>
            </div>
          </Link>
        </small>
      </>
    )
  }
}

VerifyAccountForm.propTypes = {
  username: PropTypes.string
}

export default withUser(VerifyAccountForm);