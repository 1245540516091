//import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
//import storage from '../../cookie-storage';

function* initialize (action, state) {
  try {
    console.log('Initialize credentials info...', action);

    /*
    var userPoolParams = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
      Storage: storage // new AmazonCognitoIdentity.CookieStorage({ domain: process.env.REACT_APP_COOKIE_DOMAIN, secure: false })
    };

    var userPool = new AmazonCognitoIdentity.CognitoUserPool(userPoolParams);
    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      console.log('Getting session');
      cognitoUser.getSession((err, result) => {
        console.log(err, result);
      });
    } else {
      console.log('No current user', cognitoUser);
    }
    */
  
  } catch (e) {
    console.log(e);
  }

  yield null;
};

export default initialize;