const DEFAULT_STATE = {
  loading: false,
  errors: [],
  data: {
    username: '',
    password: '',
    rememberMe: false
  }
}

function signInReducer (state = DEFAULT_STATE, action) {
  if (action.type === 'SIGN-IN.SET_USERNAME') {
    return {
      ...state,
      data: {
        ...state.data,
        username: action.payload
      }
    }
  }

  if (action.type === 'SIGN-IN.SET_PASSWORD') {
    return {
      ...state,
      data: {
        ...state.data,
        password: action.payload
      }
    }
  }

  if (action.type === 'SIGN-IN.SET_REMEMBER_ME') {
    return {
      ...state,
      data: {
        ...state.data,
        rememberMe: Boolean(action.payload)
      }
    }
  }

  if (action.type === 'SIGN-IN.AUTH') {
    return {
      ...state,
      loading: true,
      data: {
        ...state.data,
        rememberMe: Boolean(action.payload)
      }
    }
  }

  if (action.type === 'SIGN-IN.AUTH_OK') {
    return {
      ...state,
      loading: false
    }
  }

  return state;
}

export default signInReducer;