import { combineReducers } from 'redux';

import userReducer from './user';
import signInReducer from './sign-in';
import signUpReducer from './sign-up';
import invitationReducer from './invitation';

export default combineReducers({
  user: userReducer,
  invitationPage: invitationReducer,
  signInPage: signInReducer,
  signUpPage: signUpReducer,
});