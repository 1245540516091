import React from 'react';
import { Box, Title } from '../../components/Whitebox';
import { Uncontrolled as User } from '../../components/User';

class DisconnectPage extends React.Component {
  render () {
    return (
      <Box>
        <Title>Você já está conectado</Title>
        {/*<span>Para cadastrar uma nova conta é necessário você se desconectar da sua conta atual.</span>*/}
        <span>Para fazer login em outra conta você precisa desconectar da sua conta atual primeiro.</span>
        <br/>
        <br/>

        <User />
      </Box>
    )
  }
}

export default DisconnectPage;