import React, { memo, useState } from 'react';

import Button from '../../../components/Button';
import { Title } from '../../../components/Whitebox';
import { Email } from './styles';

import SignUpForm from '../../../components/InvitationSignUpForm';
import SignInForm from '../../../components/InvitationSignInForm';

function getMessage (isUserAlreadyRegistred) {
  if (isUserAlreadyRegistred) {
    return (<p>Você já possui uma conta em nossa plataforma, para continuar favor fazer <strong>login</strong> pelo botão abaixo:</p>)
  }

  return (<p>Você ainda não possui uma conta no nossa plataforma, para continuar é necessário se <strong>cadastrar</strong> pelo botão abaixo:</p>);
}

/**
 * Closed
 * @param {Object} props 
 */
const Closed = (props) => {
  const { isUserAlreadyRegistred } = props.data;

  return (
    <>
      <Title>Você recebeu um convite</Title>
      <span>Você foi convidado a participar do projeto "<strong>{props.data.project.data.name}</strong>"</span>

      <br/>
      { getMessage(isUserAlreadyRegistred) }
      <Email>{props.data.invitation.data.email}</Email>
      
      <div>
        <Button onClick={props.onStart}>{ isUserAlreadyRegistred ? 'Continuar para login' : 'Efetuar cadastro' }</Button>
      </div>
    </>
  )
}

/**
 * Opened
 * @param {Object} props 
 */
const Opened = (props) => {
  const { isUserAlreadyRegistred } = props.data;
  const { email } = props.data.invitation.data;

  if (isUserAlreadyRegistred) {
    return (
      <SignInForm
        data={{username: email}}
        onSuccess={props.onSuccess}
        onCancel={props.onCancel}
      />
    );
  }

  var form = {
    email,
    name: '',
    password: '',
    confirmPassword: ''
  };

  return (
    <SignUpForm
      data={form}
      onSuccess={props.onSuccess}
      onCancel={props.onCancel}
    />
  );
}

/**
 * Root
 * @param {Object} props 
 */
const Root = (props) => {
  const [ open, setOpen ] = useState(false);

  const handleContinue = () => {
    console.log('Root open')
    setOpen(true);
  }

  const handleSuccess = (data) => {
    console.log('Root success', data);
  }

  const handleCancel = (data) => {
    console.log('Root cancel', data);
    setOpen(false);
  }

  if (!open) {
    return (<Closed {...props} onStart={handleContinue} />)
  }

  return (<Opened {...props} onSuccess={handleSuccess} onCancel={handleCancel} />)
}

export default memo(Root);