import React from 'react';
import UserSession from '../../UserSession';

class AuthGateway extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      loading: true,
      signed: false,
      error: false
    }    

    this.refreshId = null;
    this.subscription = null;
    this.dismounted = false;
  }

  componentDidMount () {
    this.subscription = UserSession.subscribe((newUser) => {
      if (!this.dismounted) {
        this.setState({ ...newUser });
      }
    });
  }

  componentWillUnmount () {
    this.dismounted = true;

    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  renderSigned () {
    return this.props.children;
  }

  renderUnsigned () {
    if (!this.props.login) {
      return (<span>You must provide an login componented to be rendered.</span>);
    }

    const Login = this.props.login;
    return (<Login />);
  }

  renderLoading  () {
    return (<span>Entrando...</span>);
  }

  renderError () {
    return (<span>Erro ao carregar credenciais</span>);
  }

  render () {
    if (this.state.loading) {
      return this.renderLoading();
    }

    if (this.state.error) {
      return this.renderError();
    }

    if (this.state.connected) {
      return this.renderSigned();
    }

    return this.renderUnsigned();
  }
}

export default AuthGateway;